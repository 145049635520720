.container {
  &:global(.MuiContainer-root) {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    padding-bottom: 4rem;

    @media (min-width: 64.0625em){
      row-gap: 4rem;
    }
  }
}
