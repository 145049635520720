.header {
  background-color: var(--on-surface-a-100);
  padding: 0.75rem 0;

  .discord-mobile {
    display: flex;
    margin-left: auto;
    margin-right: 1rem;

    &__text {
      display: none;
      @media (min-width: calc(40.0625em)) {
        display: inline-block;
      }
    }

    @media (min-width: calc(72.0625em)) {
      display: none;
    }
  }
}

.burger {
  display: flex;

  @media (min-width: calc(72.0625em)) {
    display: none;
  }
}

.link {
  text-decoration: none;
  font-family: var(--biotif);
  font-size: 1.75rem;
  font-weight: 700;
  width: 100%;
  color: var(--on-surface-b-100);
  padding: 0.75rem 1rem;
  transition: background 0.3s;

  &:hover {
    background: var(--colors-a-010);
  }
  &:active {
    background: var(--colors-a-020);
  }
}

.navigation-desktop {
  display: none;
  @media (min-width: calc(72.0625em)) {
    margin-left: auto;
    display: flex;
  }
}
.navigation-mobile {
  display: block;
  @media (min-width: calc(72.0625em)) {
    display: none;
  }
}
