.faq {
  display: grid;
  gap: 1rem;
}

.wrapper {
  display: grid;
  gap: 1rem;
}

.title {
  &:global(.MuiTypography-root) {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.1875;
    color: var(--dark);
  }
}

.header {
  display: flex;
  align-items: center;
  flex-grow: 1;
  &__title {
    &:global(.MuiTypography-root) {
      font-size: 1.25rem;
      line-height: 1.35;
      font-weight: 700;
      color: var(--dark);
    }
  }
}

.text {
  &:global(.MuiTypography-root) {
    font-size: 1rem;
    line-height: 1.1875;
    color: var(--dark-70);
  }
}
