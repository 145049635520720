.image {
  border-radius: 1.5rem;
  border: 8px solid var(--colors-a-100);
  overflow: hidden;
  position: relative;
  opacity: 0.4;
  img {
    display: flex;
    width: 100%;
    height: auto;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    display: flex;
    padding: 1rem;
    align-items: center;

    border-radius: 6250rem;
    background: var(--on-surface-a-100);
    box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);

    svg {
      display: flex;
    }
  }
}
