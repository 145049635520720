.container {
  scroll-margin-top: var(--header-height);
}

.body {
  margin-right: -1rem;

  .header {
    margin-bottom: 1.5rem;

    .title {
      color: var(--on-surface-b-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.5rem;
    }

    .text {
      color: var(--on-surface-b-090);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      span {
        font-weight: 700;
      }
    }

    .image {
      display: none;
    }
  }

  .carousel {
    margin-bottom: 1.5rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 6.25rem;
      height: 100%;
      background: linear-gradient(
        to left,
        #fff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      right: 0;
      top: 0.5rem;
      bottom: 0.5rem;
    }
  }

  .navigation {
    margin-left: -1rem;
  }

  @media (min-width: 48.0625em) {
    margin-right: -1.5rem;

    .header {
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;
      width: 100%;
      .content {
        max-width: 50.125rem;
      }
      .image {
        display: flex;
        flex: 0 0 30%;
        max-width: 22.5rem;
        position: relative;

        svg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: auto;
        }
      }

      .carousel {
        margin-bottom: 1.5rem;
        position: relative;

        &::after {
          width: 10.25rem;
        }
      }

      .navigation {
        margin-left: -1.5rem;
      }
    }
  }

  @media (min-width: 62.0625em) {
    .header {
      .title {
        font-size: 3rem;
        margin-bottom: 0;
      }

      .text {
        font-size: 1.25rem;
      }
    }
  }
}
