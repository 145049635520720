.chip {
  display: flex;
  padding: 0.3125rem 0.75rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 62.5rem;
  background: rgba(61, 64, 213, 0.1);

  &__text {
    &:global(.MuiTypography-root) {
      color: var(--accent);
      text-align: center;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: -0.2rem;
    }
  }

  &__icon {
    display: flex;
    flex: 0 0 1.5rem;
  }
}
