.container {
  background: var(--on-surface-f);
  flex: 1;
}

.questionnaire {
  padding: 1rem;
  height: 100%;
  display: flex;

  @media (min-width: 40.0625em) {
    max-width: 90rem;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .layout {
    display: none;
    @media (min-width: 40.0625em) {
      display: block;
      position: absolute;
      top: 1rem;
      left: 1rem;
      right: 1rem;
      height: 27rem;
      border-radius: 1rem;
      background: linear-gradient(
          180deg,
          rgba(61, 64, 213, 0.62) 0%,
          #0003ac 100%
        ),
        url(../../assets//images/ques.png);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .header {
    display: none;
    @media (min-width: 40.0625em) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5rem;
      position: relative;

      &__logo {
        width: 20.75rem;

        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .footer {
    display: none;
    @media (min-width: 40.0625em) {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 2rem;
      position: relative;

      &__logo {
        width: 9.25rem;

        svg {
          width: 100%;
          height: auto;
        }
      }
      &__link {
        text-decoration: underline;
      }
      &__text,
      &__link {
        color: var(--on-surface-b-060);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }
  .card {
    border-radius: 1rem;
    background: var(--on-surface-a-100);
    box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 100%;
    width: 100%;
    position: relative;

    @media (min-width: 40.0625em) {
      max-width: 31.625rem;
      padding: 3rem;
      margin-bottom: auto;
      &__logo {
        display: none;
      }
    }

    &__logo {
      width: 8.4375rem;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .title {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .banner {
      border-radius: 0.75rem;
      background: var(--colors-a-010);
      padding: 0.75rem;
      &:global(.MuiTypography-root) {
        color: var(--colors-a-100);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}

.stepper {
  gap: 0.5rem;
  & :global(.MuiStep-root) {
    flex: 1 1 100%;

    padding: 0;
    & :global(.MuiStepLabel-iconContainer) {
      border-radius: 1000px;
      background: var(--on-surface-c);
      padding: 0;
      width: 100%;
      height: 0.375rem;
      svg {
        display: none;
      }

      &:global(.Mui-active),
      &:global(.Mui-completed) {
        border-radius: 1000px;
        background: var(--colors-a-100);
      }
    }
    & :global(.MuiStepLabel-labelContainer) {
      display: none;
    }
  }
}

.phone {
  &__error {
    & :global(.form-control) {
      border-color: var(--colors-b-020) !important;

      &:focus {
        border-color: var(--colors-a-100) !important;
      }
    }
  }

  & :global(.special-label) {
    display: block;
    color: var(--on-surface-b-070);
    font-family: var(--proxima);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    position: static;
    padding: 0;
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;
    margin-top: -1px;
  }

  & :global(.form-control) {
    padding: 0.75rem 0.875rem 0.75rem 2.875rem;
    width: 100%;
    height: 3rem;
    border-radius: 0;
    border-radius: 0.75rem;
    border: 2px solid var(--colors-a-020);
    background: var(--on-surface-a-100);
    font-family: var(--proxima);
    color: var(--on-surface-b-100, #141416);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover,
    &:focus,
    &:active {
      border-color: var(--colors-a-100);
    }

    &::placeholder {
      color: var(--on-surface-b-040);
      font-family: var(--proxima);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 1;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:-webkit-autofill:hover {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  & :global(.flag-dropdown) {
    height: 3rem;
    top: unset;
    border-radius: 0.75rem 0 0 0.75rem !important;
    border: none;
    background-color: transparent !important;
    &:hover,
    &:active {
      background-color: transparent;
    }

    & :global(.selected-flag) {
      cursor: pointer;
      padding: 0 0 0 0.875rem !important;
      width: 2.875rem;
      border: none;
      background-color: transparent !important;
      border-radius: 0.75rem 0 0 0.75rem !important;
      &:hover,
      &:active {
        background-color: transparent;
      }

      & :global(.flag) {
        transform: scale(1.5);
        transform-origin: left center;
        margin-top: -0.375rem;
      }
    }
    & :global(.arrow) {
      display: none;
    }

    & :global(.country-list) {
      border-radius: 0.75rem;
      background: var(--on-surface-a-100);
      box-shadow: 0px 4px 16px 0px rgba(4, 44, 91, 0.24);

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px var(--on-surface-b-040);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--colors-a-100);
      }

      & :global(.country) {
        padding: 0.75rem;
        font-family: var(--proxima);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:hover,
        &:global(.highlight) {
          background: var(--colors-a-010);
        }
        & :global(.flag) {
          transform: scale(1.25);
          transform-origin: left center;
          margin-right: 1rem;
        }
        & :global(.country-name) {
          color: var(--on-surface-b-100);
          margin-right: 0.5rem;
        }
        & :global(.dial-code) {
          color: var(--on-surface-b-060);
        }
      }
    }
  }
}
