.whatToLearn {
  display: grid;
  gap: 0.5rem;
  
  &__title {
    &:global(.MuiTypography-root){
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.1875;
    color: var(--dark);}
  }

  &__text {
    &:global(.MuiTypography-root) {
    font-size: 1rem;
    line-height: 1.1875;
    color: var(--dark-70);}
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    gap: 0.5rem;
  }

  &__item {
    display: flex;
    gap: 0.5rem;
  }

  &__icon {
    padding-top: 0.125rem;
  }
}

@container course-details-container (min-width: 48em) {
  .whatToLearn {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(61, 64, 213, 0.1);
  }
}
