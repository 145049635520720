.body {
  .title {
    color: var(--on-surface-b-100);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }
  .content {
    padding: 1.5rem;
    border-radius: 1rem;
    background: var(--on-surface-a-100);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &__title {
      color: var(--on-surface-b-100);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }
}

.password {
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
  @media (min-width: 40.0625em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.actions {
  width: 100%;

  @media (min-width: 35.0625em) {
    max-width: min-content;
    margin-left: auto;
  }
}
