@import "../../../../../assets/styles/mixins";

.header {
  background-color: #fafbff !important;
  min-height: var(--header-height);
  justify-content: center;

  .body {
    position: relative !important;
    padding: 0.75rem 1rem;
    background-color: transparent;
    justify-content: flex-end;
    width: 100%;
    background-color: var(--white);
  }

  @media (min-width: 62.0625em) {
    position: sticky !important;
    top: 0;
    left: 0;
    margin-top: -1rem;
    .body {
      padding: 1rem 0 0.75rem;
      &_transparent {
        background-color: transparent;
      }
      &_colored {
        background-color: #fafbff;
        transition: background-color 0.1s;
        transition-delay: 0.5s;
      }
    }
  }

  @media (min-width: 62.0625em) {
    .navigation-mobile {
      display: none;
    }
  }
}

.link {
  text-decoration: none;
  font-family: var(--biotif);
  font-size: 1.75rem;
  font-weight: 700;
  width: 100%;
  color: var(--on-surface-b-100);
  padding: 0.75rem 1rem;
  transition: background 0.3s;

  &:hover {
    background: var(--colors-a-010);
  }
  &:active {
    background: var(--colors-a-020);
  }

  &:global(.active) {
    color: var(--colors-a-100);
  }
}

.logo {
  display: block;
  max-width: 10rem;
}

.discord-mobile {
  display: flex !important;
  margin-left: auto !important;
  margin-right: 1rem !important;

  &__text {
    display: none;
    @media (min-width: calc(40.0625em)) {
      display: inline-block;
    }
  }
}

.navigation-desktop {
  display: none;
}

.burger {
  display: block;
}

@media (min-width: 62.0625em) {
  .logo {
    display: none;
  }

  .navigation-desktop {
    display: flex;
    margin-left: auto;
    transform: translateZ(1px);
    position: relative;
    backface-visibility: hidden;
  }

  .burger {
    display: none;
  }

  .discord-mobile {
    display: none !important;

    &__text {
      display: inline-block;
    }
  }
}
