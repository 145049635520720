.container {
  container-type: inline-size;
  container-name: course-details-container;

  @container course-details-container (min-width: 48em) {
    & .wrapper {
      grid-template-columns: minmax(0, 1fr) minmax(250px, 0.6fr);
      gap: 0;
      margin-top: 2.5rem;
    }

    & .link {
      font-size: 0.875rem;
      line-height: 1.21;
      display: flex;
      align-items: center;
      color: var(--dark-70);
      text-decoration: none;
      gap: 0.5rem;
      margin-bottom: 1.5rem;
      width: max-content;

      span {
        text-decoration: underline;
        text-underline-offset: 2px;
        white-space: nowrap;
      }
    }

    & .left {
      border-right: 1px solid rgba(61, 64, 213, 0.1);
      padding-right: 32px;
    }

    & .right {
      padding-left: 32px;
    }

    & .left,
    & .right {
      gap: 2rem;
    }

    & .alumniFeedbacks {
      &__desktop {
        display: grid !important;
      }
      &__mobile {
        display: none !important;
      }
    }
    & .schedule {
      &__desktop {
        display: grid !important;
      }
      &__mobile {
        display: none !important;
      }
    }
    & .requirements {
      &__desktop {
        display: grid !important;
      }
      &__mobile {
        display: none !important;
      }
    }

    & .syllabus,
    & .description,
    & .whatWeTalkAbout,
    & .whatToLearn {
      order: unset;
    }
  }
}

.link {
  display: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1.5rem;
  gap: 1.5rem;
}

.content {
  display: grid;
  gap: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;

  &__subtitle {
    &:global(.MuiTypography-root) {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.21;
      color: var(--dark-70);
      margin-bottom: 0.75rem;
    }
  }

  &__title {
    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.1875;
      color: var(--dark);
    }
  }
}

.left,
.right {
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}

.alumniFeedbacks {
  &__desktop {
    display: none !important;
  }
  &__mobile {
    display: grid !important;
  }
}

.schedule {
  &__desktop {
    display: none !important;
  }
  &__mobile {
    display: grid !important;
    order: 1;
  }
}
.requirements {
  &__desktop {
    display: none !important;
  }
  &__mobile {
    display: grid !important;
    order: 2;
  }
}

.syllabus {
  order: 3;
}

.description {
  order: 4;
}

.whatWeTalkAbout {
  order: 5;
}

.whatToLearn {
  order: 6;
}
