.nav{
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  padding-top: var(--header-height);
  margin-bottom: calc(var(--header-height) * -1);

  &__link{
    font-family: var(--biotif);
    color: var(--dark-20);
    font-weight: 700;
    font-size: clamp(1rem, 0.0883rem + 3.8898vw, 2.5rem);
    margin: 0;
    text-decoration: none;

    &:global(.active){
      border-bottom: 3px solid black;
      color: var(--dark);
    }
  }
}