.instructors {
  display: grid;
  gap: 0.5rem;

  &__title {
    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.1875;
      color: var(--dark);
    }
  }

  &__content {
    display: grid;
    gap: 1.5rem;
  }
}
