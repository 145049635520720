.container {
  container-type: inline-size;
  container-name: root-container;
  position: relative;
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;

  @container root-container (min-width: 35.75em) {
    .search {
      width: 311px;
    }

    .wrapper {
      gap: 1rem;
    }
  }
}

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.search {
  width: 100%;
}
