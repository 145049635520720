.popper {
  max-width: 311px;
  padding-bottom: 0.5rem;
  max-height: 100dvh;
  width: 100%;
  z-index: 100000;
}

.content {
  padding: 1.5rem;
  background: var(--white);
  border: 1px solid rgba(61, 64, 213, 0.05);
  box-shadow: 0px 20px 30px rgba(21, 23, 61, 0.07);
  border-radius: 12px;

  width: 100%;

  &__header {
    display: flex;
    gap: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(61, 64, 213, 0.1);
  }
  &__header-icon {
    display: inline-flex;
    height: auto;
    max-width: 100%;
    margin-right: 6px;
    margin-bottom: -3px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    list-style: none;
    margin-top: 1.5rem;
    max-height: 247px;
    height: min-content;
    overflow-y: auto;
    padding-right: 0.75rem;
    margin-right: -0.75rem;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--dark-40);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent);
    }
  }
  &__item {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    row-gap: 0.5rem;
    
  }

  &__item-icon {
    width: 15px;
    min-width: 15px;
    height: auto;
    display: flex;
    margin-right: 1rem;
    align-self: flex-start;
  }

  &__item-link,
  &__item-link_disabled {
    &:global(.MuiTypography-root) {
      display: flex;
    }
  }

  &__item-description {
    word-break: break-all;
  }
}
