.body {
  .title {
    color: var(--on-surface-b-100);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }

  .list {
    margin: 0 0 1.5rem 0;
    padding: 0;
    counter-reset: item;
    list-style: none;

    .item {
      position: relative;
      padding-left: 3.75rem;

      &::before {
        counter-increment: item;
        content: counter(item);
        position: absolute;
        left: 0;
        top: 0;
        color: var(--colors-a-100, #3d40d5);
        text-align: center;
        font-family: var(--biotif);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        display: flex;
        width: 2.25rem;
        height: 2.25rem;
        padding: 0.25rem 0.625rem 0rem 0.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 625rem;
        border: 2px solid var(--colors-a-020);
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 3rem;
          left: 1.125rem;
          bottom: -0.75rem;
          width: 2px;
          background: var(--colors-a-020);
        }
      }

      &__title {
        color: var(--on-surface-b-100);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0.25rem;
      }

      &__text {
        color: var(--on-surface-b-060);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .content {
    margin-bottom: 3rem;
  }

  .image {
    width: max-content;
    margin: 0 auto -8rem;

    svg {
      display: flex;
    }
  }

  @media (min-width: 30.0625em) {
    .actions {
      max-width: 22.5rem;
      margin: 0 auto;
    }
  }

  @media (min-width: 62.0625em) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    gap: 1.5rem;

    .title {
      grid-column: 1 / 6;
      grid-row: 1;
      font-size: 3rem;
      height: min-content;
    }

    .content {
      grid-column: 6 / -1;
      grid-row: span 2;
      margin-bottom: 0;
    }

    .actions {
      max-width: unset;
      margin: 0;
      width: min-content;
    }

    .image {
      grid-column: 2 / 6;
      margin: auto 0 -4rem auto;
      grid-row: 2;
      height: min-content;
      width: 100%;

      svg{
        max-width: 30rem;
        width: 100%;
        height: auto;
      }
    }
  }
}
