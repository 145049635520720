.slide {
  padding: 1.25rem;
  display: grid;
  grid-template-columns: 2.75rem 1fr;
  gap: 0.75rem;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  cursor: grab;

  .avatar {
    background-color: var(--neutral);
    border-radius: 0.75rem !important;
    overflow: hidden;
  }

  .userName {
    color: var(--on-surface-b-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.38em;
  }

  .courseTitle {
    color: var(--on-surface-b-060);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.38em;

  }

  .text {
    color: var(--on-surface-b-060, rgba(20, 20, 22, 0.7));
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
