.container {
  scroll-margin-top: var(--header-height);
}

.body {
  padding: 2rem 1.5rem;
  border-radius: 1.5rem;
  background: var(--accent-gradient);

  .header {
    display: flex;
    gap: 1.125rem;
    margin-bottom: 1rem;
    justify-content: space-between;

    .title {
      color: var(--on-surface-a-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .carousel {
    margin-bottom: 1.5rem;
  }

  .footer {
    &__icon {
      display: block;
      margin: 0 auto 1.5rem;
    }
    &__title {
      color: var(--on-surface-a-100);
      text-align: center;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 1.5rem;
    }
  }

  @media (min-width: 30.0625em) {
    .actions {
      margin: 0 auto;
      max-width: 22.5rem;
    }
  }

  @media (min-width: 35.0625em) {
    .carousel {
      margin-right: -1.5rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 2rem;
        background: linear-gradient(
          to left,
          #fff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        right: 0;
        top: 0.5rem;
        bottom: 0.5rem;
      }
    }
  }

  @media (min-width: 40.0625em) {
    .carousel {
      &::after {
        width: 6.25rem;
      }
    }
  }

  @media (min-width: 62.0625em) {
    padding: 2.5rem;
    .header {
      margin-bottom: 1.5rem;
      .title {
        font-size: 2.5rem;
      }
    }
    .actions {
      min-width: 16.25rem;
      width: max-content;
    }

    .carousel {
      margin-right: -2.5rem;
    }

    .footer {
      &__title {
        font-size: 3rem;
      }
    }
  }
}
