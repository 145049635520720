.header {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  flex-grow: 1;
  &__title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.1875;
    color: var(--dark);
  }
  &__count {
    font-size: 0.875rem;
    line-height: 1.21;
    color: var(--dark-60);
    white-space: nowrap;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    padding: 0.75rem 0 0.75rem 2rem;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.21;
    text-decoration-line: underline;
    color: #3d40d5;
  }

  &__icon {
    display: flex;
    align-items: center;
  }
}

.tasksList {
  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (min-width: 48em) {
  .tasks {
    border-bottom: 1px solid #DEE4F4;
  }

  .header {
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    flex-grow: 1;
    flex-direction: row;
  }
}
