
.logo {
  width: 10rem;
  svg {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
