.body {
  container-type: inline-size;
  container-name: questionnaire-modal-container;
  --margin-block: min(6rem, 12dvh);
  --margin-inline: 1.5rem;

  @media (max-width: 48em) {
    --margin-block: min(1.5rem, 12dvh);
    --margin-inline: 1rem;
  }
  background-color: var(--accent);
  border-radius: 16px;
  max-height: calc(100dvh - 1.5 * var(--margin-block));
  height: min-content;
  max-width: 1193px;
  width: 100%;
  overflow-y: auto;
  margin-top: var(--margin-block);
  margin-left: var(--margin-inline);
  margin-right: var(--margin-inline);
  outline: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 2.25rem max(1.5rem, 4cqi) max(1.5rem, 4cqi);
  position: relative;
  overflow: hidden;
  @media (min-width: 48em) {
    grid-template-columns: minmax(min-content, 0.75fr) 1fr;
    column-gap: 2rem;
    overflow: hidden;
  }
}

.content {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: max(1.125rem, 2.5cqi);
  align-self: center;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: flex-start;
  
  @media (min-width: 48em) {
    .close {
      position: absolute;
      top: max(0.75rem, 1.7cqi);
      right: 1.25rem;
      margin-top: 0;
    }
  }
  .title {
    &:global(.MuiTypography-root) {
      font-size: max(1.75rem, 3.35cqi);
      line-height: 1.4;
    }
  }
  .close {
    margin-top: -0.5rem;

  }
}

.description {
  &__desktop {
    display: none;
  }
  &__mobile {
    display: block;
  }

  @media (min-width: 48em) {
    &__desktop {
      display: block;
    }
    &__mobile {
      display: none;
    }
  }
}

.list {
  display: none;

  @media (min-width: 48em) {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    row-gap: max(1.125rem, 2.5cqi);
    margin: 0;
    padding: 0;
    padding-left: 1rem;
  }
}

.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & :global(.MuiButton-root){
    flex-grow: 1;
  }
  @media (min-width: 30em) {
    flex-wrap: nowrap;
    justify-content: flex-start;

    & :global(.MuiButton-root){
      flex-grow: 0;
    }
  }
}

.image {
  place-self: center;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  &__desktop {
    display: none;
  }
  &__mobile {
    display: flex;
    position: relative;
    width: 100%;
    margin-right: calc(max(1.5rem, 4cqi) * -1);
    justify-self: end;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
      min-width: 340px;
      margin-left: calc(max(1.5rem, 4cqi) * -1 - 8px);
    }
  }

  @media (min-width: 48em) {
    &__desktop {
      display: flex;
    }
    &__mobile {
      display: none;
    }
  }

  img {
    display: flex;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
  }
}

.button {
  font-size: 0.875rem;
  line-height: 1.21;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  color: var(--white);
  opacity: 0.98;
  border: none;
  outline: none;
  background-color: transparent;
  white-space: nowrap;
  font-family: inherit;
  cursor: pointer;
}
