.burger {
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  cursor: pointer;
  svg {
    display: flex;
  }
}
