:root {
  --white: #ffffff;
  --white-90: rgba(255, 255, 255, 0.9);
  --white-70: rgba(255, 255, 255, 0.7);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-20: rgba(255, 255, 255, 0.2);
  --white-10: rgba(255, 255, 255, 0.1);

  --accent: #3f41d5;

  --neutral: #ecf0fe;

  --warning: #f6c446;
  --yellow: #f6c344;

  --danger: #ff3d2e;

  --dark: #141416;
  --dark-90: rgba(20, 20, 22, 0.9);
  --dark-70: rgba(20, 20, 22, 0.7);
  --dark-60: rgba(20, 20, 22, 0.6);
  --dark-40: rgba(20, 20, 22, 0.4);
  --dark-20: rgba(20, 20, 22, 0.2);
  --dark-10: rgba(20, 20, 22, 0.1);
  --dark-05: rgba(20, 20, 22, 0.05);

  // new colors

  --colors-a-100: #3f41d5;
  --colors-a-020: rgba(61, 64, 213, 0.2);
  --colors-a-010: rgba(61, 64, 213, 0.1);
  --colors-a-005: rgba(61, 64, 213, 0.05);

  --on-surface-a-100: #fff;
  --on-surface-a-070: rgba(255, 255, 255, 0.7);
  --on-surface-a-060: rgba(255, 255, 255, 0.6);
  --on-surface-a-020: rgba(255, 255, 255, 0.2);
  --on-surface-a-010: rgba(255, 255, 255, 0.1);

  --on-surface-b-100: #141416;
  --on-surface-b-090: rgba(20, 20, 22, 0.9);
  --on-surface-b-070: rgba(20, 20, 22, 0.7);
  --on-surface-b-060: rgba(20, 20, 22, 0.6);
  --on-surface-b-040: rgba(20, 20, 22, 0.4);
  --on-surface-b-005: rgba(20, 20, 22, 0.05);

  --colors-b-100: #FF3D2E;
  --colors-b-010: rgba(255, 61, 46, 0.10);
  --colors-b-020: rgba(255, 61, 46, 0.20);


  --colors-d-100: #2EA1E8;
  --colors-d-010: rgba(46, 161, 232, 0.1);

  --on-surface-c: #eaeffe;
  --on-surface-f: #FAFBFF;
  
  --flowkit-green: #29CC6A;

  --accent-gradient: linear-gradient(180deg, #3d40d5 0%, #0003ac 100%);
}
