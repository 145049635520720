.form,
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width: 48em) {
    height: 100%;
  }
}

.link {
  font-family: var(--proxima);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875;
  line-height: 1.2;
  color: var(--dark-60);
  text-decoration: none;
  text-align: center;
}

.button {
  @media (max-width: 48em) {
    margin-top: auto !important;
  }
}
