.button {
  position: fixed !important;
  z-index: 1001;
  bottom: 0.5rem;
  right: 1rem;
  width: min-content;
  background-color: var(--white) !important;
  border-color: var(--accent) !important;
  color: var(--accent) !important;
  box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.2) !important;
  padding: 14.5px 24px !important;

  &__desktop{
    display: none !important;
  }
  &__mobile{
    display: flex !important;
  }

  @media (min-width:48em) {
    right: 2rem;

    &__desktop{
      display: flex !important;
    }
    &__mobile{
      display: none !important;
    }
  }
}
