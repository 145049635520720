.progress {
  flex: 1;
  padding-bottom: 0.125rem;
  &__title {
    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.2;
      margin-bottom: 0.25rem;
    }
  }

  &__bar {
    &:global(.MuiLinearProgress-root) {
      background-color: rgba(20, 20, 22, 0.05);
    }
    & :global(.MuiLinearProgress-bar) {
      background-color: var(--yellow);
    }
  }
}
