.body {
  @media (min-width: 62.0625em) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 1.5rem;
    align-items: center;
  }
  .content {
    &__image {
      aspect-ratio: 1.7;
      max-height: 22.5rem;
      width: 100%;
      border-radius: 1.5rem;
      overflow: hidden;
      margin-bottom: 1.5rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.46) 69.79%,
        #000 100%
      );
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }

      @media (min-width: 62.0625em) {
        display: none;
      }
    }

    .title {
      color: var(--on-surface-b-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 1rem;
    }

    .list {
      margin: 0 0 1.5rem 0;
      padding: 0;
      list-style: none;
      & .item {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        &__icon {
          display: flex;
          margin-bottom: 0.75rem;
        }
        &__title {
          color: var(--on-surface-b-100);
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 0.5rem;
        }
        &__text {
          color: var(--on-surface-b-060);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .actions {
      width: 100%;
      &__enroll {
        width: 100%;
      }
    }

    @media (min-width: 30.0625em) {
      .actions {
        max-width: 22.5rem;
        margin: 0 auto;
      }

      .list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 2rem;
        & .item {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    @media (min-width: 62.0625em) {
      grid-column: span 6;

      .title {
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
      }

      .actions {
        max-width: unset;
        &__enroll {
          width: max-content;
        }
      }
    }

    @media (min-width: 64.0625em) {
      padding-left: 1.25rem;
    }

    @media (min-width: 80.0625em) {
      padding-left: 2.5rem;
    }
  }
  .image {
    display: none;

    @media (min-width: 62.0625em) {
      display: flex;
      grid-column: span 6;
      border-radius: 1.5rem;
      overflow: hidden;
      position: relative;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.46) 69.79%,
        #000 100%
      );

      &__title {
        color: var(--on-surface-a-100);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        z-index: 1;
        margin-top: 1.5rem;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
    }
  }
}
