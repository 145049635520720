.wrapper {
  display: grid;
  grid-template-columns: 1fr min-content;
  margin-top: 2rem;
  overflow: hidden;
  gap: 0.5rem;
  align-items: center;
}

.title {
  &:global(.MuiTypography-root) {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.1875;
    color: var(--dark);
    grid-column: 1;
    grid-row: 1;
  }
}

.navigation {
  display: flex;
  gap: 0.5rem;
  grid-column: 2;
  grid-row: 1;
}

.button {
  width: 33px;
  height: 33px;
  background-color: rgba(61, 64, 213, 0.05);
  border-radius: 8px;
}

.swiper {
  grid-column: 1 / -1;
  grid-row: 2;
  overflow: hidden;
}

.slide {
  width: 304px !important;
  aspect-ratio: 16 / 9;
  background-color: var(--accent);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  cursor: pointer;
}

.image {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
