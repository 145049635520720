.email {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;

  &__label {
    color: var(--on-surface-b-070);
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
  }
  &__address {
    color: var(--on-surface-b-100);
    font-size: 1rem;
  }
}

.questionnaire {
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid var(--colors-a-020);
  background: var(--colors-a-005);

  @media (min-width: 40.0625em) {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    margin-bottom: 1rem;

    @media (min-width: 40.0625em) {
      margin-bottom: 0;
    }
  }

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
  }
  &__description {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-090);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
