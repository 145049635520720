.sidebar {
  display: grid;
  gap: 0.75rem;
  grid-auto-flow: row;
  grid-auto-rows: min-content;

  .link {
    font-size: 0.875rem;
    line-height: 1.21;
    display: flex;
    align-items: center;
    color: var(--dark-70);
    text-decoration: none;
    gap: 0.125rem;
    width: max-content;

    span {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-color: var(--dark-20);
      white-space: nowrap;
    }
  }

  .title {
    font-size: 1.75rem;
    line-height: 1.35;
  }

}

.modules {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  &__title {
    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875;
    }
  }
  &__subtitle {
    &:global(.MuiTypography-root) {
      font-size: 0.875rem;
      line-height: 1.21;
    }
  }
}

.list {
  list-style: none;
  margin: 0 0 0.75rem;
  padding: 0;
  &__item {
    padding: 0.5rem 0;
    border-bottom: 1px solid #dee4f4;
    display: grid;
    grid-template-columns: 1rem 1fr;
    column-gap: 0.875rem;
    row-gap: 0.25rem;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
  }

  &__item-checkbox {
    grid-column: 1;
    grid-row: 1;
    place-self: center;
  }
  &__item-title {
    grid-column: 2;
    grid-row: 1;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.17;
    color: #42444d;
    text-decoration: none;

    &:global(.active) {
      color: var(--accent) !important;
    }

    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.17;
      color: var(--dark-60);
    }
  }
  &__item-duration {
    grid-column: 2;
    grid-row: 2;

    &:global(.MuiTypography-root) {
      font-size: 0.75rem;
      line-height: 1.33;
    }
  }
}

