.card {
  display: grid;
  grid-template-columns: 55px 1fr;
  gap: 12px;
}

.avatar {
  background-color: var(--neutral);
  border-radius: 12px !important;
  overflow: hidden;
}

.media {
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;

  @media (min-width: 48em) {
    max-width: 210px;
  }
}

.wrapper {
  max-height: 204px;
  overflow-y: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px var(--dark-40);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent);
  }
}