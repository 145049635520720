.body {
  @media (min-width: 62.0625em) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 1.5rem;
    align-items: center;
  }
  .content {
    &__image {
      aspect-ratio: 1.7;
      max-height: 22.5rem;
      width: 100%;
      border-radius: 1.5rem;
      overflow: hidden;
      margin-bottom: 1.5rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (min-width: 62.0625em) {
        display: none;
      }
    }

    .title {
      color: var(--on-surface-b-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: var(--on-surface-b-090);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.75rem;
    }
    .list {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 1.5rem;
      &__item {
        color: var(--on-surface-b-090);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
    .actions {
      width: 100%;
      &__crush {
        width: 100%;
        margin-bottom: 1rem;
      }
      &__images {
        display: flex;
        gap: 1.25rem;
        align-items: center;
        justify-content: center;
        span {
          width: 2px;
          height: 29px;
          border-radius: 7px;
          background: var(--colors-a-010);
        }
      }
    }

    @media (min-width: 30.0625em) {
      .actions {
        max-width: 22.5rem;
        margin: 0 auto;
      }
    }

    @media (min-width: 48.0625em) {
      
      .title {
        margin-bottom: 0.75rem;
      }
    }

    @media (min-width: 62.0625em) {
      grid-column: span 6;
      padding: 1.5rem 0;

      .title {
        font-size: 2.5rem;
      }
      .subtitle {
        font-size: 1.25rem;
      }

      .actions {
        max-width: unset;
        display: flex;
        gap: 2rem;
        align-items: center;
        &__crush {
          width: max-content;
          margin-bottom: 0;
        }

        &__images {         
          justify-content: flex-start;
        }
      }
    }

    @media (min-width: 64.0625em) {
      padding-right: 1.25rem;
    }

    @media (min-width: 80.0625em) {
      padding-right: 2.5rem;
    }
  }
  .image {
    display: none;

    @media (min-width: 62.0625em) {
      display: flex;
      grid-column: span 6;
      border-radius: 1.5rem;
      overflow: hidden;
      position: relative;
      height: 100%;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
