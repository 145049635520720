.body {
  --margin: 1rem;
  --padding: 1rem;

  @media (min-width: 48.0625em) {
    --margin: 1.5rem;
    --padding: 2rem;
  }

  background-color: var(--white);
  border: 1px solid #eaeffe;
  box-shadow: 0px 20px 30px rgba(21, 23, 61, 0.07);
  border-radius: 16px;
  max-height: calc(100dvh - 2 * var(--margin));
  height: min-content;
  max-width: 1000px;
  width: 100%;
  overflow-y: auto;
  outline: none;
  margin: var(--margin);
  padding: var(--padding);
}

.content {
  display: grid;
  gap: 1.5rem;

  @media (min-width: 48.0625em) {
    border-right: 1px solid rgba(20, 20, 22, 0.05);
    padding-right: 2rem;
    order: 1;
  }
}

.title {
  &:global(.MuiTypography-root) {
    font-size: 2.5rem;
    line-height: 1.375;
  }
}
.course {
  @media (min-width: 48.0625em) {
    order: 2;
  }
  &__data {
    display: grid;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    padding: 16px;
    gap: 8px;
    padding: 16px;
    background-color: var(--white);
    border: 1px solid rgba(20, 20, 22, 0.05);
    border-radius: 16px;
  }
  &__title {
    &:global(.MuiTypography-root) {
      font-size: 1.25rem;
      line-height: 1.2;

      & .oldPrice {
        color: var(--on-surface-b-060);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: line-through;
      }
    }
  }
  &__image {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 16px;
    display: flex;
    object-fit: cover;
  }
  &__price {
    padding-top: 1.5rem;
    border-top: 1px solid rgba(20, 20, 22, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.wrapper {
  display: grid;
  gap: 1.5rem;
  @media (min-width: 48.0625em) {
    grid-template-columns: minmax(0, 1fr) minmax(280px, 0.59fr);
    gap: 2rem;
  }
}

.coupon {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(20, 20, 22, 0.05);
  & .form {
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
  }
}

.couponDetails {
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__discount {
    &:global(.MuiTypography-root) {
      margin-top: 0.375rem;
      color: var(--colors-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__price {
    &:global(.MuiTypography-root) {
      margin-top: 0.2rem;
      color: var(--on-surface-b-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      span {
        color: var(--on-surface-b-060);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        text-decoration: line-through;
      }
    }
  }
  &__date {
    &:global(.MuiTypography-root) {
      margin-top: 0.375rem;
      color: var(--on-surface-b-060);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
  &__info {
    &:global(.MuiTypography-root) {
      margin-top: 0.5rem;
      color: var(--on-surface-b-070, rgba(20, 20, 22, 0.7));
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
