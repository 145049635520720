.container {
  max-width: 1334px;
  padding: 2rem;
  margin: auto;
  width: 100%;
  @media (max-width: 48em) {
    height: 100%;
  }
}

.body {
  display: grid;
  grid-template-columns: minmax(431px, 1fr) auto;
  gap: 2.5rem;

  @media (max-width: 48em) {
    grid-template-columns: 1fr;
    height: 100%;
  }
}

.content {
  background-color: #fff;
  margin: 3.5rem 0 0 auto;
  max-width: 431px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  @media (max-width: 48em) {
    place-self: center;
    margin: 0;
    height: 100%;
  }
}

.logo {
  width: clamp(8.4375rem, 4.2032rem + 18.0662vw, 12.875rem);
  display: flex;
  svg {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.link {
  display: flex;
  width: max-content;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
  gap: 1.5rem;

  &__link {
    font-family: var(--biotif);
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1.75rem, 1.0344rem + 3.0534vw, 2.5rem);
    line-height: 1.375;
    color: var(--dark-20);
    text-decoration: none;

    &:global(.active) {
      color: var(--dark);
    }
  }
}

.title {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--biotif);
  font-style: normal;
  font-weight: 700;
  font-size: clamp(1.75rem, 1.0344rem + 3.0534vw, 2.5rem);
  line-height: 1.375;
  color: var(--dark);
}

.image {
  margin: -0.33rem -2.2rem -1.3rem -2rem;
  overflow: hidden;

  @media (max-width: 48em) {
    display: none;
  }

  svg {
    display: flex;
    height: 697px;
    width: auto;
  }
}
