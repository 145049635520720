.wrapper {
  max-width: 1280px;
  margin: 0 auto 100px;
  padding: 0 clamp(1.25rem, 0.4903rem + 3.2415vw, 2.5rem);

  h2 {
    font-family: var(--biotif);
    color: #141416;
    margin: 24px 0;
    font-weight: 700;
    font-size: clamp(1rem, 0.3922rem + 2.5932vw, 2rem);
  }

  h3 {
    margin: 0 0 12px 0;
    font-weight: 700;
    font-size: clamp(0.875rem, 0.799rem + 0.3241vw, 1rem);
    color: #000000;
  }

  p,ul {
    color: rgba(20, 20, 22, 0.7);
    font-size: clamp(0.875rem, 0.799rem + 0.3241vw, 1rem);
    margin: 0;
  }

}

.textBlock {
  margin: 24px 0;
}