.wrapper {
  padding: 55.137% 0 0 0;
  position: relative;
  height: 0;
  border: 0.5rem solid var(--colors-a-100);
  border-radius: 1.5rem;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
  }
}
