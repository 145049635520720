.wrapper {
  max-width: 1280px;
  margin: 0 auto 100px;
  padding: 0 clamp(1.25rem, 0.4903rem + 3.2415vw, 2.5rem);

  h3 {
    margin: 0 0 12px 0;
    font-weight: 700;
    font-size: clamp(0.875rem, 0.799rem + 0.3241vw, 1rem);
    color: #000000;
  }

  p,
  ul,
  ol {
    color: rgba(20, 20, 22, 0.7);
    font-size: clamp(0.875rem, 0.799rem + 0.3241vw, 1rem);
    margin: 0;
  }
}

.textBlock {
  margin: 24px 0;
}

ol:not([type="a"]) {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol:not([type="a"]) > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.5rem;
}
ol:where([type="a"]) {
  display: table;
  padding-left: 1rem;
}

ol:not([type="a"]) > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.5rem;
}

li ol:not([type="a"]) > li {
  margin: 0;
}

li ol:not([type="a"]) > li:before {
  content: counters(item, ".") " ";
}
