.wrapper {
  padding: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: var(--white-90);
  backdrop-filter: blur(4px);
  display: grid !important;
  place-content: center;
}

.loader {
  width: 190px;
}
