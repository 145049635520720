.container {
  container-type: inline-size;
  container-name: course-card;

  @container course-card (min-width: 48.0625em) {
    .card {
      &:global(.MuiCard-root) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &__actions {
        &:global(.MuiCardActions-root) {
          flex-direction: row;
          max-width: unset;

          & > :global(.MuiButton-root) {
            width: min-content;
          }
        }
      }

      & .mediaWrapper{
        display: flex;
        align-items: center;
      }
    }
  }
}

.card {
  display: grid;
  grid-template-columns: 1fr;
  gap: clamp(1.5rem, 2.8575cqi, 2rem);
  padding: clamp(1rem, 2.8575cqi, 2rem);

  .chip{
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &:global(.MuiCard-root) {
    background-color: var(--white);
    box-shadow: 0px 20px 30px rgba(21, 23, 61, 0.07);
    border-radius: 16px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: min-content;
    align-self: center;
  }

  &__header {
    &:global(.MuiCardHeader-root) {
      padding: 0;
    }
  }

  &__content {
    &:global(.MuiCardContent-root) {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
  }

  &__subtitle {
    &:global(.MuiTypography-root) {
      line-height: 1.1875;
    }

    span {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  &__title {
    &:global(.MuiTypography-root) {
      font-size: clamp(2rem, 3.575cqi, 2.5rem);
      line-height: 1.375;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  &__actions {
    &:global(.MuiCardActions-root) {
      padding: 0;
      gap: 0.75rem;
      flex-direction: column;
      max-width: 22.5rem;
      width: 100%;
      margin: 0 auto;

      & > * {
        width: 100%;
      }

      & > :not(:first-of-type) {
        margin-left: 0;
      }
    }
  }
  &__media {
    &:global(.MuiCardMedia-root) {
      position: relative;
      border-radius: 1.5rem;
      overflow: hidden;
      display: flex;
      background-color: var(--white);
      border: 8px solid var(--accent);

      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.discount {

  &__title {
    white-space: nowrap;
    
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      line-height: normal;
      font-weight: 400;
    }

    &_dark {
      color: var(--dark);
      position: relative;
      font-size: 1.5rem;
      line-height: normal;
      font-weight: 700;   
    }

    &_test{
      position: relative;
      &:before {
        content: "";
        background-color: var(--dark-60);
        display: block;
        position: absolute;
        height: 1px;
        left: 0;
        top: 6px;
        right: 1px;
        transform: rotate(-12.5deg);
      }
    }
  }
}
