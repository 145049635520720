@import "./assets/styles/fonts";
@import "./assets/styles/colors";
@import "./assets/styles/layout";
@import "./assets/styles/mixins";

html,
body{
  height: 100%;
  min-height: 100dvh;
}

body{
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Toastify {
  align-items: center;
  &__toast {
    padding: 1.5rem;
    background: var(--white);
    box-shadow: 0px 20px 30px rgba(21, 23, 61, 0.07);
    border-radius: 16px;
    color: var(--dark);

    &-container{
      max-width: 439px;
      width: 100%;
    }

    &-body {
      font-family: var(--proxima);
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375;
      padding: 0;
      margin-right: 1rem;
    }

    &-icon {
      margin-right: 1.5rem;
      svg{
        width: 21px;
        height: auto;
      }
    }
  }

  &__close-button {
    align-self: center;
    width: 18px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #000;
      width: 100%;
      height: auto;
    }
  }
}
