.body {
  padding: 2.5rem 0.5rem 2rem;

  @media (min-width: 25.875em){
    padding: 2.5rem 1.5rem 2rem;
  }
  @media (min-width: 72.0625em){
    padding: 2.75rem 1.5rem;
  }

  .title {
    color: var(--on-surface-b-090);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.5rem;
    @media (min-width: 48.0625em) {
      font-size: 1.25rem;
    }
  }
  .list {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
    width: max-content;

    @media (min-width: 25.875em){
      gap: 1rem;
    }

    @media (min-width: 64.0625em) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        display: flex;
        max-width: 100%;
        height: auto;
      }

      &:nth-child(1) {
        svg {
          width: 4.72019rem;
        }
      }
      &:nth-child(2) {
        svg {
          width: 4.23606rem;
        }
      }
      &:nth-child(3) {
        svg {
          width: 6.11206rem;
        }
      }
      &:nth-child(4) {
        svg {
          width: 5.41613rem;
        }
      }
      &:nth-child(5) {
        svg {
          width: 4.08481rem;
        }
      }
      &:nth-child(6) {
        svg {
          width: 5.50688rem;
        }
      }

      @media (min-width: 48.0625em) {
        &:nth-child(1) {
          svg {
            width: 6.63381rem;
          }
        }
        &:nth-child(2) {
          svg {
            width: 5.95344rem;
          }
        }
        &:nth-child(3) {
          svg {
            width: 8.58994rem;
          }
        }
        &:nth-child(4) {
          svg {
            width: 7.61188rem;
          }
        }
        &:nth-child(5) {
          svg {
            width: 5.74081rem;
          }
        }
        &:nth-child(6) {
          svg {
            width: 7.73938rem;
          }
        }
      }

    }
  }
}
