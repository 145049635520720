
.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.5rem;
  }
}
.link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:global(.MuiTypography-root) {
    color: var(--colors-a-100);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
  }
}
