
.chip {
    border-radius: 0.375rem;
    height: 1.375rem;
    padding: 0.3125rem 0.5rem;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 0.38rem;

    span {
      display: block;
      margin-bottom: -0.125rem;

      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      white-space: nowrap;
    }

    &__recorded {
      color: var(--colors-d-100);
      background: var(--colors-d-010);
    }
    
    &__live {
      color: var(--colors-b-100);
      background: var(--colors-b-010);
    }

    svg {
      display: flex;
    }
  }