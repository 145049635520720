.personalInfo {
  margin-bottom: 1.5rem;

  &__grid {
    display: grid;
    gap: 1rem;

    @media (min-width: 35.0625em) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 48.0625em) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (min-width: 62.0625em) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
.professionalInfo {
  margin-bottom: 1.5rem;
  &__top {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
    @media (min-width: 35.0625em) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
    @media (min-width: 35.0625em) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 48.0625em) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (min-width: 62.0625em) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }
}

.phone {
  &__error {
    & :global(.form-control) {
      border-color: var(--colors-b-020) !important;

      &:focus {
        border-color: var(--colors-a-100) !important;
      }
    }
  }

  & :global(.special-label) {
    display: block;
    color: var(--on-surface-b-070);
    font-family: var(--proxima);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    position: static;
    padding: 0;
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;
    margin-top: -1px;
  }

  & :global(.form-control) {
    padding: 0.75rem 0.875rem 0.75rem 2.875rem;
    width: 100%;
    height: 3rem;
    border-radius: 0;
    border-radius: 0.75rem;
    border: 2px solid var(--colors-a-020);
    background: var(--on-surface-a-100);
    font-family: var(--proxima);
    color: var(--on-surface-b-100, #141416);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover,
    &:focus,
    &:active {
      border-color: var(--colors-a-100);
    }

    &::placeholder {
      color: var(--on-surface-b-040);
      font-family: var(--proxima);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 1;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:-webkit-autofill:hover {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  & :global(.flag-dropdown) {
    height: 3rem;
    top: unset;
    border-radius: 0.75rem 0 0 0.75rem !important;
    border: none;
    background-color: transparent !important;
    &:hover,
    &:active {
      background-color: transparent;
    }

    & :global(.selected-flag) {
      cursor: pointer;
      padding: 0 0 0 0.875rem !important;
      width: 2.875rem;
      border: none;
      background-color: transparent !important;
      border-radius: 0.75rem 0 0 0.75rem !important;
      &:hover,
      &:active {
        background-color: transparent;
      }

      & :global(.flag) {
        transform: scale(1.5);
        transform-origin: left center;
        margin-top: -0.375rem;
      }
    }
    & :global(.arrow) {
      display: none;
    }

    & :global(.country-list) {
      border-radius: 0.75rem;
      background: var(--on-surface-a-100);
      box-shadow: 0px 4px 16px 0px rgba(4, 44, 91, 0.24);

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px var(--on-surface-b-040);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--colors-a-100);
      }

      & :global(.country) {
        padding: 0.75rem;
        font-family: var(--proxima);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:hover,
        &:global(.highlight) {
          background: var(--colors-a-010);
        }
        & :global(.flag) {
          transform: scale(1.25);
          transform-origin: left center;
          margin-right: 1rem;
        }
        & :global(.country-name) {
          color: var(--on-surface-b-100);
          margin-right: 0.5rem;
        }
        & :global(.dial-code) {
          color: var(--on-surface-b-060);
        }
      }
    }
  }
}

.actions{
  width: 100%;

  @media (min-width: 35.0625em) {
    max-width: min-content;
    margin-left: auto;
  }
}
