.card {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.25rem;
  position: relative;
  height: 100%;
  border-radius: 1rem;
  border: 1px solid var(--on-surface-b-005);
  background: var(--on-surface-a-100);

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  .image {
    border-radius: 0.75rem;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      display: flex;
    }
  }

  .title {
    color: var(--colors-a-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .duration {
    color: var(--on-surface-b-060);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    color: var(--on-surface-b-090);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .price {
    color: var(--on-surface-b-070);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 0.75rem;

    span{
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      text-align: center;
    }

    &__free {
      height: 1.375rem;
      padding: 0.075rem 0.5rem 0.075rem;
      border-radius: 0.375rem;
      background: var(--flowkit-green);
      color: var(--on-surface-a-100);
    }
  }
}
