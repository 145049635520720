.slide {
  box-shadow: 0px 1.4297188520431519px 3.5742971897125244px 0px
    rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 1.25rem;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../../../../../../assets/images/youtube-red.svg);
    background-repeat: no-repeat;
    background-size: 4rem;
    background-position: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;

    border-radius: 1.25rem;
    background-color: var(--colors-a-020);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: flex;
  }
}
