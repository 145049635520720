.card {
  display: grid;
  gap: 0.5rem;
  &__header {
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 0.75rem;
  }
}

.avatar {
  background-color: var(--neutral);
  border-radius: 12px !important;
  overflow: hidden;
}

.info {
  display: grid;
  gap: 0.25rem;
}

.rating {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  & :global(.MuiSvgIcon-root){
    margin-top: -3px;
  }
}
