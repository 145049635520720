.schedule {
  display: grid;
  gap: 0.5rem;

  &__title {
    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.1875;
      color: var(--dark);
    }
  }

  &__text {

    &:global(.MuiTypography-root) {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--dark);

    svg {
      display: inline-block;
      margin-bottom: -0.125rem;
      margin-right: 0.75rem;
    }}
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
      td {
        vertical-align: top;
        border-bottom: 0.0625rem solid rgba(61, 64, 213, 0.1);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &:nth-child(1) {
          white-space: nowrap;
          padding-right: 1.5rem;

          span {
            
            display: block;
            margin-left: 1.75rem;
            color: var(--dark-60);
            font-family: Proxima Nova;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}

@container course-details-container (min-width: 48em) {
  .schedule {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(61, 64, 213, 0.1);
  }
}
