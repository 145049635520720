.wrapper {
  display: flex;
  gap: 1.5rem;
}
.button {
  flex: 1;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 0.875rem 1.5rem;
  background-color: var(--dark-05);
  border-radius: 12px;
  min-height: 3rem;
  font-family: var(--proxima);
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2;
  color: var(--dark);
  cursor: pointer;
  svg {
    display: inline-flex;
  }
  span {
    display: inline-flex;

    @media (max-width: 48em) {
      display: none;
    }
  }
}

.google {
  flex-grow: 1;
}

.google,
.facebook {
  display: flex;
  @media (max-width: 48em) {
    flex: 1;
  }
}
