.slide {
  container-type: inline-size;
  container-name: slide-container;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
    background-color: var(--white);
    border: 1px solid #eaeffe;
    box-shadow: 0px 20px 30px rgba(21, 23, 61, 0.07);
    border-radius: 24px;
    position: relative;
  }

  @container slide-container (inline-size < 45em) {
    .visible {
      opacity: 1;
      visibility: visible;
    }
    .hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  @container slide-container (inline-size >= 45em) {
    &__wrapper {
      grid-template-columns: minmax(250px, 0.72fr) 1fr;
      gap: 1.5rem;
      padding: 1.5rem;
    }

    .button {
      display: none;
    }

    .card {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      gap: 0.75rem;

      .content {
        gap: 0.75rem;
      }

      &__title {
        font-size: 1.5rem;
      }
      &__price {
        font-size: 1.75rem;
      }

      &__title,
      &__rating,
      &__media,
      &__text,
      &__price {
        order: initial;
      }
    }

    .wrapper {
      position: static;
      padding-left: 1.5rem;
      padding-bottom: 2rem;
      padding-top: 2rem;
      border-left: 2px solid var(--dark-10);
      gap: 1.5rem;

      .courses-title {
        display: block;
      }
      .courses-title-mobile {
        display: none;
      }

      .list {
        .item {
          padding: 12px 12px 12px 18px;
        }
      }
    }
  }
}

.card {
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  will-change: opacity, visibility;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__title {
    order: 3;
  }
  &__rating {
    order: 2;
  }
  &__media {
    order: 1;
  }
  &__text {
    order: 4;
  }
  &__price {
    order: 5;
  }
}

.wrapper {
  will-change: opacity, visibility;
  position: absolute;
  left: 1rem;
  top: 1rem;
  bottom: 1rem;
  right: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: var(--white);

  .courses-title {
    display: none;
  }
  .courses-title-mobile {
    display: block;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    overflow-y: auto;
    padding-right: 0.5rem;
    padding-bottom: 0.125rem;
    height: 100%;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--dark-40);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent);
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    gap: 0.75rem;
    text-decoration: none;
    background: var(--white);
    border: 1px solid var(--dark-05);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: relative;

    &:not(:last-child)::after {
      content: url(../../../../assets/images/arrow-down.svg);
      display: block;
      width: 9px;
      height: 12px;
      bottom: -14px;
      left: 1.25rem;
      position: absolute;
      pointer-events: none;
    }

    &__text {
      white-space: nowrap;
    }
  }
}

.button {
  margin-top: auto;
  margin-left: 0 !important;
}
