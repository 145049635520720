.modules {
  display: grid;
  gap: 0.75rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  &__title {
    &:global(.MuiTypography-root) {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.1875;
    color: var(--dark);}
  }
  &__count {
    &:global(.MuiTypography-root) {
    font-size: 0.875rem;
    line-height: 1.21;
    color: var(--dark-60);
    white-space: nowrap;}
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    padding: 0.75rem 0;
    border-top: 1px solid rgba(61, 64, 213, 0.1);
    display: flex;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__text {
    flex-grow: 1;
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.21;
    color: var(--dark);

    &_free {
      text-decoration-line: underline;
      color: #3d40d5;
    }
  }
  &__duration {
    font-size: 0.875rem;
    line-height: 1.21;
    color: var(--dark-60);
    white-space: nowrap;
  }
}

@container course-details-container (min-width: 48em) {
  .modules {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(61, 64, 213, 0.1);
  }

  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    flex-grow: 1;
  }
}
