.alumniFeedbacks {
  gap: 0.5rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  &__title {
    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.1875;
      color: var(--dark);
    }
  }

  &__content {
    display: grid;
    gap: 1.5rem;
  }
}

.rating {
  display: flex;
  gap: 0.625rem;

  &__title {
    &:global(.MuiTypography-root) {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.1875;
      color: var(--accent);
    }
  }
}
