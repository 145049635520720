.form,
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width:48em) {
    height: 100%;
  }
}

.link {
  color: inherit;
}

.button {
  @media (max-width: 48em) {
    margin-top: auto !important;
  }
}
