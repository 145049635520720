.container {
  height: 100%;
}

.body {
  display: grid;
  place-items: center;
  place-content: center;
  height: 100%;
  gap: 2rem;
  padding: 1.5rem 0;
}

.image{
    svg{
        max-width: 100%;
        height: auto;
    }
}
