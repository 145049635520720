.courses {
    container-type: inline-size;
    container-name: courses-container;
    flex-grow: 1;
  
    &__title {
      display: none;
    }
  
    &__list {
      display: grid;
      gap: 1.5rem;
      grid-template-columns: 1fr;
      position: relative;
    }
  
    @container courses-container (min-width: 35.75em) {
      &__wrapper {
        background-color: var(--white);
        padding: 1.5rem;
        border-radius: 16px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        grid-template-columns: repeat(2, minmax(250px, 1fr));
      }
      &__title {
        display: block;
      }
      &__list {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
        grid-auto-flow: row;
        grid-auto-rows: 1fr;
      }
    }
  
    @container courses-container (min-width: 52.875em) {
      &__list {
        grid-template-columns: repeat(3, minmax(250px, 1fr));
      }
    }
  
    @container courses-container (min-width: 70em) {
      &__list {
        grid-template-columns: repeat(4, minmax(250px, 1fr));
      }
    }
  }