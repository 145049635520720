.modules {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  flex-grow: 1;
  &__title {
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.1875;
      color: var(--accent);
    }
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    padding: 0.75rem 0 0.75rem 2rem;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.21;
    text-decoration-line: underline;
    color: #3d40d5;
  }

  &__icon {
    display: flex;
    align-items: center;
  }
}

.modulesList {
  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@container course-details-container (min-width: 48em) {
  .modules {
    padding-top: 0.75rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(61, 64, 213, 0.1);
  }

  .header {
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
  }
}

.info {
  display: flex;
  padding: 0.75rem;
  gap: 0.875rem;
  border-radius: 0.75rem;
  border: 1px solid var(--dark-20);
  &__text {
    &:global(.MuiTypography-root) {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--dark-70);
    }
  }

  &__icon {
    display: flex;
    flex: 0 0 1.5rem;
  }
}

.textList {
  margin: 0;
}

.record {
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
  }

  &__link{
    text-decoration: none;
    &:global(.MuiTypography-root) {
      color: var(--colors-a-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    svg{
      display: flex;
    }
  }
}
