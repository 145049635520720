.container {
  scroll-margin-top: var(--header-height);
}

.body {
  border-radius: 1.5rem;
  background: var(--on-surface-a-100);

  .header {
    display: flex;
    gap: 1.125rem;
    margin-bottom: 1rem;
    justify-content: space-between;

    .title {
      color: var(--on-surface-b-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .carousel {
    margin-bottom: 1.5rem;
    margin-right: -1rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 2.5rem;
      background: linear-gradient(
        to left,
        #fff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      right: 0;
      top: 0.5rem;
      bottom: 0.5rem;
    }
  }

  @media (min-width: 30.0625em) {
    .actions {
      margin: 0 auto;
      max-width: 22.5rem;
    }
  }

  @media (min-width: 48.0625em) {
    .carousel {
      margin-right: -1.5rem;

      &::after {
        width: 6.25rem;
      }
    }
  }

  @media (min-width: 62.0625em) {
    .header {
      margin-bottom: 1.5rem;
      .title {
        font-size: 2.5rem;
      }
    }
    .actions {
      min-width: 16.25rem;
      width: max-content;
    }
  }
}
