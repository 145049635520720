.container {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.showMoreBtn {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.21;
  text-decoration-line: underline;
  display: flex;
  color: var(--accent);
  margin-top: 0.75rem;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 0;
  background-color: transparent;
  border: none;
}

.overlay {
  background: linear-gradient(
    180deg,
    rgba(250, 251, 255, 0) 0%,
    #fafbff 96.88%
  );
  &_blue {
    background: linear-gradient(
      180deg,
      rgba(250, 251, 255, 0) 0%,
      #fafbff 96.88%
    );
  }
  &_white{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  }
  height: 4rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
}
.wrapper {
  width: 100%;
  position: relative;
}
