.container {
  background-color: #fafbff;
  flex-grow: 1;
  &__inner {
    height: 100%;
    padding: 1rem 1rem 0 !important;

    @media (min-width: 62.0625em) {
      padding: 1rem !important;
    }
  }
}

.drawer {
  display: none;
  @media (min-width: 62.0625em) {
    display: block;
    position: sticky;
    top: 1rem;
    height: calc(100dvh - 2rem);
  }
}

.outlet {
  padding-top: 4rem;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 62.0625em) {
    padding-top: 0;
  }
}

.wrapper {
  display: flex;
  gap: 1.5rem;
  position: relative;
  height: 100%;
}

.close {
  color: var(--white);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex-grow: 1;

  &__link {
    position: relative;

    .list__icon {
      padding: 0.5rem;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 12px;

      svg {
        display: flex;
        path {
          fill: var(--white);
        }
      }
    }

    .list__text {
      & :global(.MuiTypography-root) {
        font-family: var(--proxima);
        font-style: normal;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.2;
        color: var(--white-70);
      }
    }

    &:global(.active) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 0.25rem;
        height: 100%;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background-color: var(--white);
      }
      .list__icon {
        background: var(--white);
        svg {
          display: flex;
          path {
            fill: var(--accent);
          }
        }
      }

      .list__text {
        & :global(.MuiTypography-root) {
          color: var(--white);
        }
      }
    }
  }
}

.footer {
  @media (max-width: 62em) {
    margin: 0 -1rem -1rem;
  }
}
