.lecture {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  padding: 1.875rem;
  border-radius: 0.9375rem;
  background: var(--on-surface-a-100);
  box-shadow: 0px 3px 15px 0px rgba(20, 67, 114, 0.08);
}

.title {
  &:global(.MuiTypography-root) {
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.22;
    color: #42444d;
  }
}

.description {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-070);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.actions {
  width: max-content;
  margin-left: auto;
}
